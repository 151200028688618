import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { API } from 'aws-amplify'; 
import { createDistributor } from '../graphql/mutations'


import './inquiry.css';
import { Row } from 'react-bootstrap';
export default function Inquiry(props) {
    const [validated, setValidated] = useState(false);
    const [ errors, setErrors ] = useState({})
    const addapi = async(desc,name,email,phone)=>{
        let type = "Inquiry";
        await API.graphql({
            query: createDistributor,
            variables: {
              input: {
                type,
                name,
                email,
                phone,
                desc
              },
            },
            authMode: 'API_KEY'
          })
    }
    const handleSubmit = (event) => {
        
      const form = event.currentTarget;
      const newError ={}
      if(event.target[0].value.length === 0){
        newError.info = "Inquiry Detail Can not be empty"
      }
      if(event.target[1].value.length === 0){
        newError.name = "Name Can not be empty"
      }
      if(event.target[2].value.length === 0){
        newError.email = "Email Can not be empty"
      }
      if(event.target[3].value.length === 0){
        newError.phone = "Phone number Can not be empty"
      }
      if ( Object.keys(newError).length > 0 ) {
        // We got errors!
        setErrors(newError)
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
      } else {
        props.onHide(false);
            addapi(event.target[0].value,event.target[1].value,event.target[2].value,event.target[3].value)
            event.preventDefault();
            event.stopPropagation();
        
            setValidated(true);
        }
    };

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
        <Modal.Header className=' borderless'>
            <Modal.Title id="contained-modal-title-vcenter" className='form-title mx-auto'>
            Enter Buying Requirement Details
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
            <Container>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="inquiryForm.textareacontrol">
                        <Form.Control 
                        as="textarea" 
                        rows={3} 
                        placeholder="* To get accurate quotes.
                        Please include product name, order quantity,
                        usage, special requests if any in your inquiry."
                        required
                        defaultValue=""
                        isInvalid={ !!errors.info }/>
                        <Form.Control.Feedback type='invalid'>
                            { errors.info }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inquiryForm.name">
                        <Form.Control 
                        type="name" 
                        placeholder="Enter Name" 
                        required
                        defaultValue=""
                        isInvalid={ !!errors.name }/>
                        <Form.Control.Feedback type='invalid'>
                            { errors.name }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inquiryForm.emailcontrol">
                        <Form.Control 
                        type="email" 
                        placeholder="Email Address" 
                        required
                        defaultValue=""
                        isInvalid={ !!errors.email }/>
                        <Form.Control.Feedback type='invalid'>
                            { errors.email }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inquiryForm.phonecontrol">
                        <Form.Control 
                        type="number" 
                        placeholder="Phone Number" 
                        required
                        defaultValue=""
                        isInvalid={ !!errors.phone }/>

                        <Form.Control.Feedback type='invalid'>
                            { errors.phone }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                        <Button  className="ms-auto" variant="outline-danger" type="submit">Send Inquiry</Button>
                    </Row>
                </Form>
            </Container>
        </Modal.Body>
        </Modal>
    );
}
