import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import './itemmodal.css';
import Inquiry from "../forms/Inquiry";
const LoginModal = (props) => {
  const [inquiryShow, setInquiryShow] = useState(false);
  return (
    <>
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="ms-auto">
          {props.product.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row>
            <Col xs={12} md={6} className="m-auto"> 
              <img src={props.product.img} height={300} width={250} alt={props.product.name} className="d-block mx-auto img-fluid"/>
            </Col>
            <Col xs={12} md={6} className="m-auto">
              
              <br/>
              <p className="product-title">Product Details : <br/></p>
              <p className="product-detail">
              Physical Form : {props.product.content[0]}<hr className="hr-line"/>
              Product Name : {props.product.content[1]}<hr className="hr-line"/>
              Product Type : {props.product.content[2]}<hr className="hr-line"/>
              Shape : {props.product.content[3]}<hr className="hr-line"/>
              Processing : {props.product.content[4]}<hr className="hr-line"/>
              Color : {props.product.content[5]}<hr className="hr-line"/>
              Grade : {props.product.content[6]}<hr className="hr-line"/>
              Packaging Available : {props.product.content[7]}<hr className="hr-line"/>
              Bulk Order / Supply Availability : {props.product.content[8]}<hr className="hr-line"/>
              </p>
            </Col>
          </Row>

        </Container>
      </Modal.Body>
      <Modal.Footer >
          <Button className="mx-auto" size="lg" variant="outline-danger" onClick={() => toggleForm(setInquiryShow,props.onHide )}>
            Send Inquiry
          </Button>
        </Modal.Footer>
    </Modal>
    <Inquiry show={inquiryShow} onHide={() => setInquiryShow(false)} />
    </>
  );
};

function toggleForm(setInquiryShow, onhide){
  onhide(false);
  setInquiryShow(true);

}

export default LoginModal;