import React from "react"
import Carousel from 'react-bootstrap/Carousel';
import one from '../assets/slides/one_mini.jpg';
import two from '../assets/slides/two_mini.jpg';
import three from '../assets/slides/three_mini.jpg';
import oneLarge from '../assets/slides/one.jpg';
import twoLarge from '../assets/slides/two.jpg';
import threeLarge from '../assets/slides/three.jpg';
import './carousal.css'

class Carousal extends React.Component {

  state = {
    innerWidth: window.innerWidth
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({innerWidth: window.innerWidth})
  }

  getImage(innerWidth) {
    const image ={}
    if (innerWidth <= 700){
      image.one = one
      image.two = two
      image.three = three
    }
    else{
      image.one = oneLarge
      image.two = twoLarge
      image.three = threeLarge
    } 
    return image
  }
  render() {
    const image = this.getImage(this.state.innerWidth);
    return (
      <>
      <Carousel className='carousal-main' fade>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src={image.one}
            height="450"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src={image.two}
            height="450"
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src={image.three}
            height="450"
            alt="Third slide"
          />
          
        </Carousel.Item>
      </Carousel>
    </>
    );
  }
}

export default Carousal;