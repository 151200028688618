import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { API } from 'aws-amplify'; 
import { createDistributor } from '../graphql/mutations'

import './distributor.css';
import { Row } from 'react-bootstrap';
export default function Distributor(props) {
    const [validated, setValidated] = useState(false);
    const [ errors, setErrors ] = useState({})
    const addapi = async(name,business,phone,city,pincode,desc)=>{
        let type = "Distributor";
        await API.graphql({
            query: createDistributor,
            variables: {
              input: {
                type,
                name,
                business,
                phone,
                city,
                pincode,
                desc
              },
            },
            authMode: 'API_KEY'
          })
    }
    const handleSubmit = (event) => {
        
      const form = event.currentTarget;
      const newError ={}
      console.log(event)
      if(event.target[0].value.length === 0){
        newError.name = "Name Can not be empty"
      }
      if(event.target[2].value.length === 0){
        newError.phone = "Phone Can not be empty"
      }
      if(event.target[3].value.length === 0){
        newError.city = "City Can not be empty"
      }
      if(event.target[4].value.length === 0){
        newError.pin = "Pin Code Can not be empty"
      }
      if(event.target[5].value.length === 0){
        newError.desc = "Description Can not be empty"
      }
      if ( Object.keys(newError).length > 0 ) {
        // We got errors!
        setErrors(newError);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
      } else {
        props.onHide(false);
           addapi(event.target[0].value,event.target[1].value,event.target[2].value,event.target[3].value,event.target[4].value,event.target[5].value)
            
            event.preventDefault();
            event.stopPropagation();
        
            setValidated(true);
        }
    };

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
        <Modal.Header className=' borderless'>
            <Modal.Title id="contained-modal-title-vcenter" className='form-title mx-auto'>
                To become our distributor fill your details
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
            <Container>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    
                    <Form.Group className="mb-3" controlId="inquiryForm.name">
                        <Form.Control 
                        type="name" 
                        placeholder="Enter Name" 
                        required
                        defaultValue=""
                        isInvalid={ !!errors.name }/>
                        <Form.Control.Feedback type='invalid'>
                            { errors.name }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inquiryForm.business">
                        <Form.Control 
                        type="name" 
                        placeholder="Enter Business Name"
                        defaultValue=""/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inquiryForm.phonecontrol">
                        <Form.Control 
                        type="number" 
                        placeholder="Phone Number" 
                        required
                        defaultValue=""
                        isInvalid={ !!errors.phone }/>

                        <Form.Control.Feedback type='invalid'>
                            { errors.phone }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inquiryForm.city">
                        <Form.Control 
                        type="name" 
                        placeholder="City" 
                        required
                        defaultValue=""
                        isInvalid={ !!errors.city }/>
                        <Form.Control.Feedback type='invalid'>
                            { errors.city }
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="inquiryForm.pin">
                        <Form.Control 
                        type="number" 
                        placeholder="Pin Code" 
                        required
                        defaultValue=""
                        isInvalid={ !!errors.pin}/>

                        <Form.Control.Feedback type='invalid'>
                            { errors.pin }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inquiryForm.desc">
                        <Form.Control 
                        type="name" 
                        placeholder="Enter Description" 
                        required
                        defaultValue=""
                        isInvalid={ !!errors.desc }/>
                        <Form.Control.Feedback type='invalid'>
                            { errors.desc }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                        <Button  className="ms-auto" variant="outline-danger" type='submit'>Submit</Button>
                    </Row>
                </Form>
            </Container>
        </Modal.Body>
        </Modal>
    );
}
