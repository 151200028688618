
import React from 'react'
import { Container } from 'react-bootstrap'
import './footer.css'
export default function Footer() {
  return (
    <Container fluid className='footer-section'>
    </Container>
  )
}
