import { Container } from "react-bootstrap";
import './about.css';

function About() {
  return (
    <Container fluid className="mt-5 about-section" id="about">
        <h6 className='about-text'>
            About Sanghini Spices
        </h6>
        <p className="about-para">
        We, at Sanghini Spices are dedicated to providing our 
        customers with a delightful culinary experience through 
        our range of naturally delicious and healthy spices. We 
        believe that the secret to great-tasting food lies in the 
        quality of ingredients. That's why we meticulously select 
        and source the finest spices that are rich in vitamins, 
        minerals, and nutrients.<br/><br/>
        Our team takes great care in every step of the process, from 
        cultivation to packaging, ensuring that our spices reach you 
        in their freshest form in hygienic way. We understand the 
        importance of preserving the natural goodness of spices, 
        which is why we handle them with utmost care, maintaining their 
        aromatic flavors and health benefits. <br/><br/>
        Whether you are a home cook or a professional chef, our 
        spices are crafted to elevate your culinary creations. We are 
        passionate about delivering the authentic flavors of nature 
        straight to your kitchen, enhancing the taste of your dishes 
        and making your meals truly memorable. <br/><br/>
        Join us on this flavorful journey as we bring you the finest 
        spices that not only add a burst of taste to your food but also 
        contribute to your overall well-being. Experience the Sanghini 
        difference and elevate your culinary adventures today.
<br/>
        </p>
    </Container>
  );
}

export default About;