/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDistributor = /* GraphQL */ `
  mutation CreateDistributor(
    $input: CreateDistributorInput!
    $condition: ModelDistributorConditionInput
  ) {
    createDistributor(input: $input, condition: $condition) {
      id
      type
      name
      business
      email
      phone
      city
      pincode
      desc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDistributor = /* GraphQL */ `
  mutation UpdateDistributor(
    $input: UpdateDistributorInput!
    $condition: ModelDistributorConditionInput
  ) {
    updateDistributor(input: $input, condition: $condition) {
      id
      type
      name
      business
      email
      phone
      city
      pincode
      desc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDistributor = /* GraphQL */ `
  mutation DeleteDistributor(
    $input: DeleteDistributorInput!
    $condition: ModelDistributorConditionInput
  ) {
    deleteDistributor(input: $input, condition: $condition) {
      id
      type
      name
      business
      email
      phone
      city
      pincode
      desc
      createdAt
      updatedAt
      __typename
    }
  }
`;
