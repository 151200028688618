import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './navbar/NavBar';
import Carousal from './carousal/Carousal'
import { Latest } from './Item/Item';
import About from './about/About';
import Contact from './contact/Contact';
import Footer from './footer/Footer';

import React, { useState } from 'react';

import Distributor from "./forms/Distributor";
function App() {

  const [show, setShow] = useState(true);
  return (
    <>
    <div className="App app-main">
     <NavBar />
     <Carousal />
     <Latest/>
     <About/>
     <Contact/>
     <Footer/>

    </div>

  <Distributor show={show} onHide={() => setShow(false)} />
</>
  );
}

export default App;
