import React, { useState } from 'react';
import { Container, Row, Col, Stack } from 'react-bootstrap'
import './contact.css';
import {ImOffice} from 'react-icons/im';
import {BiSolidFactory} from 'react-icons/bi';
import {AiTwotoneMail} from 'react-icons/ai';
import {BiSolidPhoneCall} from 'react-icons/bi';
import {FaFacebook} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import Distributor from "../forms/Distributor";

export default function Contact() {

  const [show, setShow] = useState(false);
  return (
    <Container fluid className="mt-5 contact-section" id="contact">
        <h6 className='contact-title'>
            Contact Details
        </h6>
        <h3 className='contact-head'>
            <b>Sanghini Spices</b>
        </h3> 
        <Row className='contact-detail'>
          <Col xs={12} md={6} className="m-auto">
            <ImOffice/> <b>Registered Office Address</b>:  A-157, Jawahar Nagar, Bharatpur (Raj) - 321001<br/>
            <BiSolidFactory/><b>Factory Address</b>:  Opposite B.R. Oill mill, Nai Mandi, Bharatpur (Raj) - 321001<br/>
            <AiTwotoneMail/><b>Email</b>:  info@sanghinifoods.com<br/>
            <BiSolidPhoneCall/><b>Call Us</b>:  +91 8619749331<br/>
            <BiSolidPhoneCall/><b>For Trade related Inquiries</b>:  +91 80055003797<br/>
          </Col>
          <Col xs={12} sm={6}>
              <Container className=''>
                <Row>
                  <Col xs= {{ span: 2, offset: 4 }}>
                    <a href="https://www.facebook.com/profile.php?id=100094472324861" target="_blank" rel="noreferrer" className='icons'><FaFacebook/></a>
                  </Col>
                  <Col xs= {{ span: 2, offset: 0 }}>
                    <a href="https://www.instagram.com/sanghinifoods/" target="_blank" rel="noreferrer"  className='icons'><FaInstagram/></a>
                  </Col>
                  <Col xs= {{ span: 2, offset: 0 }}>
                    <a href="https://www.youtube.com/channel/UC89M6DbxzDTYb9jKCg87QKQ"  target="_blank" rel="noreferrer" className='icons'><FaYoutube/></a>
                  </Col>
                </Row>
                
              </Container>
            <Container>
            <Col xs= {{ span: 5, offset: 5 }}>
                    <Button  className="openpop" variant="danger" onClick={() => setShow(true)}>Become a Distributor</Button> 
                </Col>
            </Container>
            
          </Col>
          
        </Row>
         
    <Distributor show={show} onHide={() => setShow(false)} />
    </Container>
  )
}
