import Card from 'react-bootstrap/Card';
import './item.css';
import { Container, Row, Col} from 'react-bootstrap';
import React, { useState} from "react";
import ItemModal from './ItemModal';
const blogs = [
  { name: "Red Chilli Powder" ,img: '../assets/spices/lalmirch.png', content: ['Powder','Red Chilli Powder','Dried','Powder','Blended','Red','Premium','50gm, 100gm, 200gm. 500gm','Yes'] },
  { name: "Turmeric (Haldi) Powder" ,img: '../assets/spices/haldi.png', content: ['Powder','Turmeric (Haldi) Powder','Dried','Powder','Blended','Yellow','Premium','50gm, 100gm, 200gm. 500gm','Yes'] },
  { name: "Coriander (Dhaniya) Powder" ,img: '../assets/spices/dhaniya.png', content: ['Powder','Coriander (Dhaniya) Powder','Dried','Powder','Blended','Green','Premium','50gm, 100gm, 200gm. 500gm','Yes'] },
  { name: "Dry Mango (Amchoor) Powder" ,img: '../assets/spices/amchur.png', content: ['Powder','Dry Mango (Amchoor) Powder','Dried','Powder','Blended','Brown','Premium','50gm, 100gm, 200gm. 500gm','Yes'] },
  { name: "Garam Masala" ,img: '../assets/spices/garam.png', content: ['Powder','Garam Masala','Dried','Powder','Blended','Brown','Premium','50gm, 100gm, 200gm. 500gm','Yes'] },
  { name: "Cumin Seed (Jeera)" ,img: '../assets/spices/jeera.png', content: ['Whole','Cumin Seed (Jeera)','Sun Dried','Granule','Blended','Brown','Premium','50gm, 100gm, 200gm. 500gm','Yes'] },
  { name: "Fennel (Saunf)" ,img: '../assets/spices/fennel.png', content: ['Whole','Fennel (Saunf)','Sun Dried','Granule','Blended','Green','Premium','50gm, 100gm, 200gm. 500gm','Yes'] },
  
];


function ItemCard(props){
    const [itemModal, setItemModal] = useState(false);
    return (
      <>
        <Col xs={12} sm={6} md={4} lg={4} className='mt-3'>
            <div className='Item card' style={{ width: '20rem' }} onClick={() => setItemModal(true)}>
                <Card className="item-card" style={{ display:'inline-block'}}>
                <Card.Img height={200} variant="top" src={props.data.img} className='px-5'/>
                <Card.Body className='item-body'>
                    <Card.Title className='item-title'>{props.data.name}</Card.Title>
                    </Card.Body>
                </Card>
            </div>
        </Col>
        <ItemModal show={itemModal} onHide={() => setItemModal(false)} product={props.data}/>
      </>
      );
}

export default function Item() {
  return (
    <div className="Items">
      <h6 className='all-item'>
        All Blogs
      </h6>
      {blogs.map((data,idx) => (
        <ItemCard key={idx} data={data}/>
      ))}
    </div>
  );
}

export function Latest() {
  
  
  return (
    <Container fluid className="Items" id="products">
      <h6 className='item-text'>
        Our Top Products
        
      </h6>
      <Row>
        {blogs.map((data,idx) => (
            <ItemCard key={idx} data={data}/>
        ))}
      </Row>
    </Container>
  );
}
