import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/spices/logo.png';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './navbar.css';
const NavBar=() =>{
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="nav-main" >
      <Container>
        <Navbar.Brand href="#home" style={{padding:0}}>
        <img
              src={logo}
              width="140"
              height="115"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#home" style={{fontSize: '22px',fontFamily:'Times New Roman',fontWeight:700}}>Home</Nav.Link>
            <Nav.Link href="#about" style={{fontSize: '22px',fontFamily:'Times New Roman',fontWeight:700}}>About</Nav.Link>
            <Nav.Link href="#products" style={{fontSize: '22px',fontFamily:'Times New Roman',fontWeight:700}}>Products</Nav.Link>
            {/* <NavDropdown style={{fontSize: '22px',fontFamily:'Times New Roman',fontWeight:700}} title="Products" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#products" style={{fontSize: '22px',fontFamily:'Times New Roman',fontWeight:700}}>
                Ground Spices
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#products" style={{fontSize: '22px',fontFamily:'Times New Roman',fontWeight:700}}>
                Blended Spices
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#products" style={{fontSize: '22px',fontFamily:'Times New Roman',fontWeight:700}}>
                Whole Spices
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Nav>
            <Nav.Link href="#contact" style={{fontSize: '22px',fontFamily:'Times New Roman',fontWeight:700}}>Contact Us</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default NavBar;